.testimonials {
    padding: 50px 20px;
    background-color: rgb(156, 206, 253);
  }
  
  .testimonials h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
  }
  
  .testimonial.testimonials {
    padding: 50px 20px;
    background-color: #bddfff;
  }
  
  .testimonials h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
  }
  
  .testimonial-grid {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .testimonial {
    width: 30%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .testimonial strong {
    display: block;
    font-size: 14px;
    margin-top: 10px;
  }

  
  @media (max-width: 768px) {
    .testimonial-grid {
      flex-direction: column;
    }
  
    .testimonial {
      width: 100%;
      margin-bottom: 20px;
    }
  }-grid {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .testimonial {
    width: 30%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .testimonial strong {
    display: block;
    font-size: 14px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .testimonial-grid {
      flex-direction: column;
      max-width: 100%;
    }
  
    .testimonial {
      width: 100%;
      margin-bottom: 20px;
    }
  }