body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

h2 {
  color: #333;
}

button {
  outline: none;
  transition: background-color 0.3s ease;
}

button:hover {
  cursor: pointer;
}
