.footer {
  padding: 40px 20px;
  background-color: #4CAF50;
  color: #fff;
  text-align: left;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-column {
  flex: 1;
  min-width: 250px;
  color: #fff;
}

.footer-column h3, .footer-column h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.footer-column p {
  margin-bottom: 15px;
  font-size: 14px;
}

.quick-links {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
}

.quick-links li {
  margin-bottom: 10px;
}

.quick-links a {
  text-decoration: none;
  font-size: 14px;
}

.quick-links a:hover {
  text-decoration: underline;
}