.key-value {
  padding: 50px 20px;
  background-color: #e8f3fe;
  text-align: center;
}

.key-value h2 {
  font-size: 32px;
  margin-bottom: 40px;
}

.key-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 0 5vw;
}

.column {
  width: 22%;
  text-align: center;
  padding: 10px 20px;
}

.column .icon {
  font-size: 40px; /* Adjust icon size */
  color: #4CAF50; /* Change icon color */
  margin: 0 auto 15px auto; /* Centering the icon */
}

.column h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.column p {
  font-size: 16px;
  color: #666;
}

@media (max-width: 1000px) {
  .key-columns {
    flex-wrap: wrap;
    justify-content: center;
  }

  .column {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .key-columns {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
    margin-bottom: 20px;
  }
}