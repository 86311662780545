.hero {
    position: relative;
    height: 90vh;
    display: flex;
    align-items: center;
    padding-left: 10vw;
    justify-content: left;
    text-align: left;
    background-color: #f8f9fa;
    color: #333;
    overflow: visible;
    z-index: 1;
}

.hero-content {
    max-width: 70vw;
}

.hero-headline {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 20px;
}

.hero-subheadline {
    font-size: 20px;
    margin: 0 0 30px;
}

.cta-buttons {
    display: flex;
    justify-content: left;
    gap: 20px;
}

.cta-student,
.cta-business {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.cta-student:hover,
.cta-business:hover {
    background-color: #0056b3;
}

.shape {
    position: absolute;
    opacity: 0.7;
    z-index: -1;
    animation: float 4s ease-in-out infinite;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shape:hover {
    transform: scale(1.3) translateY(-10px) rotate(15deg);
}

/* top left square */
.shape-1 {
    width: 20vw;
    height: 20vw;
    background-color: transparent;
    border: clamp(10px, 5vw, 30px) solid #007BFF;
    top: -10vw;
    left: -5vw;
    rotate: 2deg;
}

/* circle */
.shape-2 {
    width: 15vw;
    height: 15vw;
    background-color: transparent;
    border: clamp(7px, 3vw, 20px) solid #4CAF50;
    border-radius: 50%;
    top: 20%;
    left: 70%;
    animation-duration: 6s;
}

/* bottom right square */
.shape-3 {
    width: 10vw;
    height: 10vw;
    background-color: transparent;
    border: clamp(3px, 2vw, 10px) solid #007BFF;
    bottom: -5vh;
    right: 3vw;
    animation-duration: 5s;
    rotate: 45deg;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2vh);
    }
}