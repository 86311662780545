.how-it-works {
  padding: 50px 20px;
  background-color: #b2d9fe;
}

.how-it-works h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
}

/* Steps container for desktop and mobile */
.steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.step {
  width: 22%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.step:hover {
  transform: translateY(-10px);
}

.step h3 {
  font-size: 24px;
  color: #4CAF50;
  margin-bottom: 15px;
}

.step p {
  font-size: 16px;
  margin-bottom: 10px;
}

.gamification-subheader {
  margin-top: 50px;
  background-color: #f0f8ff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.gamification-subheader h3 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #4CAF50;
}

.gamification-subheader p {
  text-align: center;
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
  }

  .step {
    width: 100%;
    margin-bottom: 20px;
  }
}