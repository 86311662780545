.get-started-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    gap: 20px;
    padding: 20px;
}

.get-started-page h1 {
    margin-top: 40px;
    margin-bottom: 20px;
}

.airtable-embed {
    width: 100%;
    max-width: 500px;
    height: 900px;
}