.featured-projects {
  padding: 50px 20px;
  background-color: #dbecfc;
}

.featured-projects h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
}

.featured-projects h3 {
  margin-bottom: 20px;
  padding-left: 40px;
}

.section-title {
  font-size: 28px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #333;
}

.project-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 0 40px;
}

.project-card {
  width: 30%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth effect */
}

.project-card:hover {
  transform: scale(1.05); /* Scale the card up slightly */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.project-card h3 {
  font-size: 22px;
  margin-bottom: 10px;
  padding-left: 0px;
}

.project-card p {
  font-size: 16px;
  padding-bottom: 20px;
}

.project-card .btn {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.project-card .btn:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .project-card {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  .project-card h3 {
    text-align: center;
  }
  .project-card p {
    text-align: center;
  }
  .project-card .btn {
    display: inline-block;
    text-align: center;
  }
}